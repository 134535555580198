import type { CurrentUser } from "@fscrypto/domain";
import { omit } from "lodash-es";

export function load() {
  if (window.rudderanalytics) {
    const writeKey = window.ENV.RUDDERSTACK_WRITE_KEY;
    const url = window.ENV.RUDDERSTACK_DATAPLANE_URL;
    if (writeKey && url) {
      // configUrl and destSDKBaseURL are overriding the default values, we are sending those requests through cloudflare
      window.rudderanalytics.load(writeKey, url, { configUrl: url, destSDKBaseURL: `${url}/integrations` });
    }
  }
}

interface CustomTracking {
  [key: string]: string | null | boolean;
}

export type TrackingInterface = "Earn";

export function tracking(trackingMsg: string, interfaceMsg: TrackingInterface, customTrackingObj?: CustomTracking) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = customTrackingObj || {};
  if (window?.rudderanalytics) {
    window.rudderanalytics.track(trackingMsg, {
      category: "Website",
      interface: interfaceMsg,
      ...rest,
    });
    if (window.ENV.APP_ENV === "development") {
      console.log("event tracking", trackingMsg, interfaceMsg, customTrackingObj);
    }
  }
}

/** Helper to create a track function for a given interface. Just to reduce noise in components. */
export function trackInterface(ifc: TrackingInterface) {
  return (eventName: string, data?: CustomTracking) => {
    tracking(eventName, ifc, data);
  };
}

export function identify(currentUser: CurrentUser) {
  // the id field is reserved in rudderstack so we need to rename it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const user: any = { ...omit(currentUser, "id"), ...{ userId: currentUser.id } };

  if (window?.rudderanalytics) {
    window.rudderanalytics.identify(
      user.userId,
      {
        email: user.email,
        username: user.username,
        avatar: user.avatarUrl,
        createdAt: user.createdAt,
        userProfileId: user.profileId,
        contextProfileId: user.currentProfileId,
      },
      {
        integrations: {
          "Google Analytics": true,
          "Snowflake Analytics": true,
          Intercom: true,
        },
      },
    );
    if (window.ENV.APP_ENV === "development") {
      console.log("identify tracking", currentUser);
    }
  }
}

export function page(pathName: string) {
  console.log(window.ENV.APP_ENV);
  if (window.rudderanalytics) {
    window.rudderanalytics.page(pathName, pathName);
    if (window.ENV.APP_ENV === "development") {
      console.log("page tracking", pathName);
    }
  }
}
